import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import "pure-react-carousel/dist/react-carousel.es.css"
import SEO from "../components/seo"
import ActualiteGrid from "../components/ActualiteGrid"

const ActualitesList = props => {
  const { data } = props

  const { edges: actualites } = data.allNodeActualites
  const { currentPage, numPages, skip, limit } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <Layout>
      <SEO
        title="Actualité voiture neuve au maroc"
        description="Toute l'actualite des voitures neuves au maroc"
      />

      <section className="section-header bg-dark text-white pb-7 pb-lg-11">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center">
              <h1 className="display-2 mb-4">
                Toute l'actualité des voitures neuves
              </h1>
              <p className="lead d-none d-lg-inline">
                Actualité de l'automobile au maroc, Photos, Videos des voitures
                neuves au maroc (Renault, Peugeot, Opel, Mercedes ..)
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-lg line-bottom-light">
        <div className="container mt-n8 mt-md-n9 mt-lg-n12 z-2">
          <ActualiteGrid title="" withHeader={true} actualites={actualites} />
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center w-100">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {!isFirst && (
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to={`/actualites/${prevPage}`}
                          rel="prev"
                        >
                          <i aria-hidden="true" className="angle left icon"></i>
                        </Link>
                      </li>
                    )}
                    {Array.from({ length: numPages }, (_, i) => (
                      <li className="page-item" key={i}>
                        <Link
                          className="page-link"
                          key={`pagination-number${i + 1}`}
                          to={`/actualites/${i === 0 ? "" : i + 1}`}
                        >
                          {i + 1}
                        </Link>
                      </li>
                    ))}
                    {!isLast && (
                      <li className="page-item">
                        <Link
                          className="page-link"
                          to={`/actualites/${nextPage}`}
                          rel="next"
                        >
                          <i
                            aria-hidden="true"
                            className="angle right icon"
                          ></i>
                        </Link>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ActualitesList

export const pageQuery = graphql`
  query ActualitesList($skip: Int!, $limit: Int!) {
    allNodeActualites(
      sort: { fields: created, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          field_description
          relationships {
            field_tags {
              name
              path {
                alias
              }
            }
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
